import React, { useState, useEffect, useRef } from 'react';
import classes from './Modal.module.css';

import Modal from "../Modals/Modal";
import ShareListItem from '../Share/ShareListItem';

import { db, auth } from '../../config';

import ShareSocials from '../Share/ShareSocials';
import SaveWork from '../Forms/SaveWork';

import { doc, updateDoc, Timestamp } from 'firebase/firestore';

const ShareModal = ( { group, setShowShare } ) => {

    const [items, setItems] = useState(); //list of items that have been shared
    const festivalName = group?.festival?.name;
    const defaultMessage = `Hey Friends! I'm off to ${festivalName} and I'm short some gear. Do you have any of the following you could lend me? `;
    const [message, setMessage] = useState(defaultMessage);
    const messageRef=useRef();
    const user = auth.currentUser;

    const requestHandler = async (platform) => {
      //console.log(platform, items);
      //make a shallow copy of group items
      const updatedItems = [...group?.items];
      //go through all group items and update their status if they are in the list that have just been shared - no multiples yet
      updatedItems?.forEach(item => {
        if (items?.some( i => i.id === item.id)) {
          item.organise?.map( org => {
            org.status = 'Requested';
            org.sharePlatform = platform
          })
          //item.status='Requested';
        }
      })
      try {
          const groupRef = doc(db, 'groups', group.id);
          await updateDoc(groupRef, {
              items: updatedItems,
              lastUpdate: Timestamp.fromDate(new Date())
          });
      } catch (err) {
          alert("There was a problem updating your item.")
      }
    }
    
    const removeItem = (item) => {
      //find which item they are editing 
      const itemIndex = items.findIndex(i => i.id === item.id);
      //make a shallow copy of the existing items array, remove item and update state
      const updatedItems = [...items];
      updatedItems.splice(itemIndex, 1);
      setItems(updatedItems)
    }

     //filter items according to whether they are still needed 
     useEffect(() => {
      if (!group) return;
      const items = group.items?.filter((item,index) => {
          let result = false;
          item.organise.forEach(org => {
              if ( org.method === 'Need' ) result = true;
          });
          return result ? item : null; 
      });
      setItems(items);
    }, [group]);

    if (user.isAnonymous) { 
      
      return (
        <Modal 
        align='top' 
        back 
        title={`You don't have an account yet.`}
        onCancel={()=>setShowShare(false)}
        >
          <div className={classes.shareSignupContainer}>
          <p>You're not logged in. Press the unlock button to log in or create an account.</p>
          <SaveWork group={group} icon='unlock'/>
          </div>
        </Modal>
    )
    } 


    return (

        <Modal 
            align='top' 
            back 
            title='See if your mates can lend you stuff'
            onCancel={()=>setShowShare(false)}
            onConfirm={()=>setShowShare(false)}
            >

              <div className={classes.shareModalTableHeader}>
                <div className={classes.shareModalTableHeaderCell}> 
                  What you need
                </div>
                <div className={classes.shareModalTableHeaderCell}> 
                  How many
                </div>
              </div>

              { items?.map ((item, index) => 
                  <ShareListItem
                      removeItem={removeItem}
                      key={index} 
                      item={item} 
                      group={group}
                  />
              ) }

              <div style={{margin:'10px 0 0 0'}}>Write a message</div>
              <textarea 
                ref={messageRef} 
                defaultValue={defaultMessage}
                placeholder="Add message" 
                className={classes.shareMessage}
                onChange={(e)=>setMessage(e.target.value)}
                >
              </textarea>

            <ShareSocials 
              message={message} 
              requestHandler={requestHandler}
              group={group}
              items={items}
              />
        
        </Modal>

    );

}

export default ShareModal;