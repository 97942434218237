//old categories
/*export const CATEGORIES = [
    'Camping gear',
    'Cleaning',
    'Food',
    'Electronics',
    'Toiletries',
    'Clothing',
    'Utensils'
  ];
*/
  export const CATEGORIES = [
    'Camping gear',
    'Food',
    'Electronics',
    'Toiletries',
    'Clothing',
    'Utensils'
  ];

  export const SORT_CATEGORIES = [
    {id:'categories', title:'Items'},
    {id:'members', title:'Team members'},
  ];

  export const SHARE_CATEGORIES = [
    {id:'all', title:'All items'},
    {id:'current', title:'Current view'},
  ];
  export const ADD_CATEGORIES = [
    {id:'category', title:'Category'},
    {id:'item', title:'Item'},
  ];
  export const OPTIONS_CATEGORIES = [
    {id:'private', title:'Make private'},
    {id:'delete', title:'Delete category'},
  ];

  export const METHODS = [
    {id:'Bring', title:'Bring', abbreviation:'Br', ecoscore:5},
    {id:'Borrow', title:'Borrow', abbreviation:'Bw', ecoscore:5},
    {id:'Hire', title:'Hire', abbreviation:'H', ecoscore:5},
    {id:'Buy', title:'Buy', abbreviation:'B', ecoscore:1},
    {id:'Need', title:'Need', abbreviation:'N', ecoscore:5},
  ];

  export const WEATHERTIPS = [
    {id:'rain', title:'Rain', tip:'Get ready to dance in the rain! Make sure you pack wet weather gear - a rain jacket and gumboots are a must.'},
    {id:'cloud', title:'Cloudy', tip:'A few clouds today.'},
    {id:'cold', title:'Cold', tip:'Brr… It’s going to be chilly! Check that you’ve packed some warm clothes, especially at night!'},
    {id:'hot', title:'Hot', tip:'It’s going to be hot! Make sure you bring a reusable water bottle, sunnies, hat and sunscreen ready to slip slop slap! '},
    {id:'storm', title:'Storm', tip:'Storms are brewing.. Bring some extra rope and tape to make sure your tent and marquees are securely tied down.'},
    {id:'sun', title:'Sunny', tip:'The sun will be out today. Avoid the dreaded festival sunburn by packing a hat, sunnies and sunscreen!'},
    {id:'suncloud', title:'Fine', tip:'Not too hot or cold! Make sure you pack some warmer clothes in case it cools down at night.'},
    {id:'wind', title:'Windy', tip:'Be careful of the wind or your gear might go flying! Use extra rope and pegs to make sure your gazebo and tents are fastly secured to the ground. '},
  ]

  export const DEFAULTVALUES = [
    "Add information about hiring options at the festival if applicable",
    "Tip about hiring item (if applicable)",
    "Add tips on where or how to buy this item",
    "n/a",
    "<p>n/a</p>",
    "<p><span>n/a</span></p>",
    "<p>N/A</p>",
    "<p><span>N/A</span></p>",
    "<p></p>",
    "<p>Add information about hiring options at the festival if applicable</p>",
    "<p><span>Add information about hiring options at the festival if applicable</span></p>"
  ]

  export const TUTORIALS = [
    {id:'1', title:'Welcome', tagline:'to your tailored festival camping planner Camping Buddy!', img:'tutorial1.png'},
    {id:'2', title:'Select your festival', tagline:'to pre-populate information or create your own.', img:'tutorial2.png'},
    {id:'3', title:'Customise', tagline:'a basic camping list.', img:'tutorial3.png'},
    {id:'4', title:'Edit your list', tagline:'and add more categories or items by using the + button.', img:'tutorial4.png'},
    {id:'5', title:'Assign tasks', tagline:"to your camping buddies by clicking 'organise me' next to an item.", img:'tutorial5.png'},
    {id:'6', title:'Borrow, hire and re-use', tagline:"your gear to generate a strong earth score.", img:'tutorial6.png'},
    {id:'7', title:'Add buddies', tagline:"to your team on the home or organise screens.", img:'tutorial7.png'},
    {id:'8', title:'Save', tagline:"your list to edit on the go.", img:'tutorial8.png'},
    {id:'9', title:'Sign up', tagline:'for notifications about your festival, weather updates, and access promotions.', img:'tutorial9.png'},
  ];


